import React, { useEffect, useState } from 'react'
import auger from 'object-auger'
import styled from 'styled-components'
import Button from 'components/button/button'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import toHandle from 'utils/to-handle'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import Spacer from 'components/new/spacer'

const defaultState = {
  category: '',
  subcategory: '',
  manufacturer: '',
  model: '',
}

const UsedEquipmentQuickSearch = () => {
  const { allSanityUsedEquipmentCategory, allMachineFinderEquipment } = useStaticQuery(graphql`
    {
      allSanityUsedEquipmentCategory(sort: { fields: [position, title], order: ASC }) {
        nodes {
          title
        }
      }
      allMachineFinderEquipment {
        group(field: category___title) {
          fieldValue
          nodes {
            subcategory {
              title
            }
            manufacturer
            model
          }
        }
      }
    }
  `)

  const [{ categories, categoryLookup }, setCategories] = useState({})

  useEffect(() => {
    // List of categories
    const categories = allSanityUsedEquipmentCategory.nodes.map(node => node.title)

    /**
     * Lookup object with nested hierarchy
     * {
     *   "category": {
     *     "subcategory": {
     *       "manufacturer": {
     *         "model": 0
     *       }
     *     }
     *   }
     * }
     */
    const categoryLookup = categories.reduce((obj, category) => {
      obj[category] = {}
      return obj
    }, {})

    allMachineFinderEquipment.group.forEach(({ fieldValue: category, nodes }) => {
      nodes.forEach(node => {
        auger.set(
          categoryLookup,
          [category, node.subcategory.title, node.manufacturer, node.model],
          1
        )
      })
    })

    setCategories({
      categories,
      categoryLookup,
    })
  }, [allMachineFinderEquipment, allSanityUsedEquipmentCategory])

  const [state, setState] = useState(defaultState)

  const { category, subcategory, manufacturer, model } = state

  const handleChange = e => {
    const val = e.target.value
    const type = e.target.getAttribute('name').toLowerCase()

    if (val === state[type]) {
      return
    }

    const newState = {}
    if (type === 'subcategory') {
      newState.category = state.category
    }
    if (type === 'manufacturer') {
      newState.category = state.category
      newState.subcategory = state.subcategory
    }
    if (type === 'model') {
      newState.category = state.category
      newState.subcategory = state.subcategory
      newState.manufacturer = state.manufacturer
    }

    newState[type] = val

    setState({ ...defaultState, ...newState })
  }

  const search = () => {
    if (category) {
      const link = `/used-equipment/${[
        toHandle(category),
        toHandle(subcategory),
        toHandle(manufacturer),
        toHandle(model),
      ]
        .filter(val => val)
        .join('/')}/`

      navigate(link)
    } else {
      navigate('/used-equipment/all/')
    }
  }
  return (
    <>
      <Container>
        <label>
          Category
          {/* eslint-disable-next-line jsx-a11y/no-onchange */}
          <select
            className={!!category ? 'filled' : ''}
            value={category}
            onChange={handleChange}
            name='Category'
            placeholder='Select a category'
          >
            <option value=''>Select a category</option>
            {categories
              ? categories.map(category => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))
              : null}
          </select>
        </label>
        <label>
          Subcategory
          {/* eslint-disable-next-line jsx-a11y/no-onchange */}
          <select
            className={!!subcategory ? 'filled' : ''}
            value={subcategory}
            onChange={handleChange}
            name='Subcategory'
            placeholder='Select a subcategory'
            disabled={!!category ? false : true}
          >
            <option value=''>Select a subcategory</option>
            {auger.has(categoryLookup, [category]) &&
              Object.keys(categoryLookup[category])
                .sort()
                .map(subcategory => (
                  <option key={subcategory} value={subcategory}>
                    {subcategory}
                  </option>
                ))}
          </select>
        </label>
        <label>
          Manufacturer
          {/* eslint-disable-next-line jsx-a11y/no-onchange */}
          <select
            className={!!manufacturer ? 'filled' : ''}
            value={manufacturer}
            onChange={handleChange}
            name='Manufacturer'
            placeholder='Select a manufacturer'
            disabled={!!subcategory ? false : true}
          >
            <option value=''>Select a manufacturer</option>
            {!!auger.has(categoryLookup, [category, subcategory]) &&
              Object.keys(categoryLookup[category][subcategory])
                .sort()
                .map(manufacturer => (
                  <option key={manufacturer} value={manufacturer}>
                    {manufacturer}
                  </option>
                ))}
          </select>
        </label>
        <label>
          Model
          {/* eslint-disable-next-line jsx-a11y/no-onchange */}
          <select
            className={!!model ? 'filled' : ''}
            value={model}
            onChange={handleChange}
            name='Model'
            placeholder='Select a model'
            disabled={!!manufacturer ? false : true}
          >
            <option value=''>Select a model</option>
            {!!auger.has(categoryLookup, [category, subcategory, manufacturer]) &&
              Object.keys(categoryLookup[category][subcategory][manufacturer])
                .sort()
                .map(model => (
                  <option key={model} value={model}>
                    {model}
                  </option>
                ))}
          </select>
        </label>
      </Container>
      <Spacer size='m' />
      <SearchButton onClick={search} color='green' ghost>
        Search Used Equipment
      </SearchButton>
    </>
  )
}

const Container = styled.div`
  margin: 0 auto;
  max-width: 600px;
  width: 100%;

  @media (min-width: 600px) {
    ${clearfix};

    label {
      ${column('1/2', '24px')}
    }
  }
`

const SearchButton = styled(Button)`
  margin: 0 auto;
`

export default UsedEquipmentQuickSearch
