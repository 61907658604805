import React from 'react'
import styled from 'styled-components'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Content from 'components/new/content'
import Button from 'components/button/button'
import { GatsbyImage } from 'gatsby-plugin-image'
import H2 from 'components/new/typography/h2'
import P from 'components/new/typography/p'
import Spacer from 'components/new/spacer'

const BrandsModule = props => {
  const { productLinesImage } = useStaticQuery(graphql`
    {
      productLinesImage: file(relativePath: { eq: "product-lines-hero.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  return (
    <Brands {...props}>
      <BrandsBackground>
        <GatsbyImage
          image={productLinesImage.childImageSharp.gatsbyImageData}
          objectFit='cover'
          objectPosition='50% 50%'
          alt=''
          style={{ width: '100%', height: '100%' }}
        />
      </BrandsBackground>
      <BrandsContent kind='full'>
        <H2>More brands to serve you better</H2>
        <P>
          We’re dedicated to serving you, the farmer, the property owner, the hard working American.
          That’s why carry the top brands that share the same values we do. Hard work, quality
          products and customer experience.
        </P>
        <Spacer size='s' />
        <Button as={ButtonLink} to='/product-lines/'>
          View Product Lines
        </Button>
      </BrandsContent>
    </Brands>
  )
}

const Brands = styled.div`
  display: block;
  position: relative;
  height: auto;
  width: 100%;
`

const BrandsBackground = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 0;
`

const BrandsContent = styled(Content)`
  position: relative;
  z-index: 1;

  h2 {
    color: #fff;
    text-align: center;
  }

  p {
    color: #fff;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
`

const ButtonLink = styled(Link)`
  display: block;
  margin: 20px auto 0;
  max-width: 200px;
  text-decoration: none;
`

export default BrandsModule
