import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import H2 from 'components/new/typography/h2'
import P from 'components/new/typography/p'
import Button from 'components/button/button'
import ReactImageGallery from 'react-image-gallery'
import Style from './style.js'
import Spacer from 'components/new/spacer'
import Content from 'components/new/content'
import { Bullet, BulletContainer } from 'components/new/bullet'
import { PausePlayButton, PausePlayButtonContainer } from 'components/new/pause-play-button'
import NavArrow from 'components/new/nav-arrow'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const HeroSlider = ({ autoPlay, items, startIndex, ...props }) => {
  const galleryEl = useRef(null)
  const [itemsCount, setItemsCount] = useState(items.length)
  const [currentIndex, setCurrentIndex] = useState(startIndex)
  const [isPlaying, setIsPlaying] = useState(autoPlay)

  useEffect(() => {
    setItemsCount(items.length)
  }, [items.length])

  const onSlide = i => {
    setCurrentIndex(i)
  }

  const handlePausePlay = () => {
    setIsPlaying(!isPlaying)

    if (isPlaying) {
      galleryEl.current.pause()
    } else {
      galleryEl.current.play()
    }
  }
  return (
    <>
      <Style />
      <ReactImageGallery
        ref={galleryEl}
        items={items}
        autoPlay={autoPlay}
        startIndex={startIndex}
        renderCustomControls={() => (
          <CustomControls>
            <BulletContainer>
              {Array.from({ length: itemsCount }).map((_, i) => (
                <Bullet
                  active={currentIndex === i}
                  index={i}
                  onClick={() => {
                    galleryEl.current.slideToIndex(i)
                  }}
                  kind='dark'
                  key={`Bullet${i}`}
                />
              ))}
            </BulletContainer>
            <PausePlayButtonContainer>
              <PausePlayButton onClick={handlePausePlay} isPlaying={isPlaying} kind='dark' />
            </PausePlayButtonContainer>
          </CustomControls>
        )}
        onSlide={onSlide}
        {...props}
      />
    </>
  )
}

HeroSlider.defaultProps = {
  autoPlay: true,
  infinite: true,
  renderItem: item => {
    return (
      <SlideContainer>
        <ImageContainer>
          <Image image={item.image} alt='' />
        </ImageContainer>
        <TextContainer>
          <StyledContent kind='full'>
            <Title>{item.title}</Title>
            <P>{item.description}</P>
            <Spacer size='m' />
            <ButtonContainer>
              {item.link ? (
                <Button as={Link} to={item.link}>
                  {item.buttonText || 'Learn more'}
                </Button>
              ) : null}
              {item.outboundLink ? (
                <Button as={OutboundLink} href={item.outboundLink}>
                  {item.buttonText || 'Learn more'}
                </Button>
              ) : null}
            </ButtonContainer>
          </StyledContent>
          <Overlay overlayOpacity={item.overlayOpacity || 0.25} />
        </TextContainer>
      </SlideContainer>
    )
  },
  renderLeftNav(onClick, disabled) {
    return <LeftNav direction='left' kind='dark' disabled={disabled} onClick={onClick} />
  },
  renderRightNav(onClick, disabled) {
    return <RightNav direction='right' kind='dark' disabled={disabled} onClick={onClick} />
  },
  showBullets: false,
  showFullscreenButton: false,
  showPlayButton: false,
  showThumbnails: false,
  slideInterval: 5000,
  startIndex: 0,
}

const CustomControls = styled.div`
  margin: 28px auto 8px;
  position: absolute;
  bottom: 0;
  text-align: center;
  z-index: 11;
  width: 100%;
`

const StyledNavArrow = styled(NavArrow)`
  padding: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateY(37.5px);
  z-index: 10;

  @media (max-width: 899px) {
    display: none;
  }
`

const LeftNav = styled(StyledNavArrow)`
  left: 0;
`

const RightNav = styled(StyledNavArrow)`
  right: 0;
`

const SlideContainer = styled.div`
  height: 500px;
  position: relative;

  @media (min-width: 900px) {
    height: 600px;
  }
`

const ImageContainer = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
`

const Image = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
`

const TextContainer = styled.div`
  height: 100%;
  overflow: visible;
  position: relative;
  white-space: initial;
  width: 100%;
  z-index: 10;

  h2,
  p {
    color: #fff;
  }
`

const StyledContent = styled(Content)`
  left: 50%;
  padding: ${props => `${props.theme.size.l} 0`};
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  > * {
    max-width: 600px;
  }

  p {
    font-size: 21px;
  }

  @media (min-width: 900px) {
    padding: ${props => props.theme.size.xxl};
    transform: translate(-50%, -50%) translateY(37.5px);
  }
`

const Title = styled(H2)`
  color: #fff;
  font-size: 48px;
  margin: 0;
`

const Overlay = styled.div`
  background: ${props => `rgba(0, 0, 0, ${props.overlayOpacity})`};
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
`

const ButtonContainer = styled.div`
  a {
    display: inline-block;
    text-decoration: none;
  }
`

export default HeroSlider
