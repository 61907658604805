import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
    .image-gallery {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
    }

    .image-gallery-content {
        position: relative;
        top: 0;
    }

    .image-gallery-slide-wrapper {
        position: relative;
    }

    .image-gallery-slide-wrapper.left,
    .image-gallery-slide-wrapper.right {
        display: inline-block;
        width: calc(100% - 113px);
    }

    @media (max-width: 768px) {
        .image-gallery-slide-wrapper.left,
        .image-gallery-slide-wrapper.right {
            width: calc(100% - 84px);
        }
    }

    .image-gallery-slides {
        overflow: hidden;
        position: relative;
        white-space: nowrap;
    }

    .image-gallery-slide {
        background: #fff;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .image-gallery-slide.center {
        position: relative;
    }

    .image-gallery-slide img {
        width: 100%;
    }
`
